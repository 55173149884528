import React from "react";
import Index from "./route/Index";

const App = () => {
return (
  <div>
    <Index />
  </div>
)
}

export default App;

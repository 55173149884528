import React from "react";
import "./HomeStyles.scss";

const Discovery = () => {
  return (
    <div className="discovery-section">
      Discovery
    </div>
  )
}

export default Discovery;
